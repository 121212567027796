import { format } from 'date-fns';
import React, { useEffect, useState } from "react";
import { ParseJwt } from '../../../core/utils/functions'
import Api from '../services/FormulariosServices'
import { ToastContainer } from "react-toastify";
import { useForm } from "react-hook-form";
import Loading from "../../../core/utils/loading";
import { Link } from 'react-router-dom';

const Formularios = () => {

    const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();

    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);

    const [error, setError] = useState(false);
    const [data, setData] = useState([]);

    const [inicioPagina, setInicioPagina] = useState(0);
    const [finPagina, setFinPagina] = useState(9);
    const [cambioPagina, setCambioPagina] = useState(5);
    const [paginador, setPaginador] = useState([]);
    const [cantPaginas, setCantPaginas] = useState(0);
    const [options, setOptions] = useState({
        limit: 10,
        offset: 0
    })

    const handdleSearchCode = async (data) => {
        const newData = {
            ...options,
            codigoId: data.codigo
        }
        setOptions(newData);
        // await loadData();
    }

    const itemsPaginator = (cant) => {
        const num = cant || 1;
        return Array.from({ length: num }, (_, i) => i + 1);
    };

    const cambiarPagina = (limit = 5, currentIndex = 0) => {
        if (currentIndex) {
            const offset = (limit * currentIndex) - limit;
            setOptions(prevOptions => ({ ...prevOptions, offset }));

            let nuevoInicioPagina = currentIndex;

            if (cantPaginas - currentIndex < cambioPagina) {
                nuevoInicioPagina = cantPaginas - (cambioPagina + 1);
            } else if (currentIndex > 1) {
                nuevoInicioPagina = currentIndex - 2;
            }

            if (nuevoInicioPagina <= 1) {
                nuevoInicioPagina = 0;
            }

            setInicioPagina(nuevoInicioPagina);

            if (cantPaginas > 1) {
                let nuevoFinPagina = cantPaginas + 1;
                if (cantPaginas > (cambioPagina + 1)) {
                    nuevoFinPagina = (cambioPagina + 1) + nuevoInicioPagina;
                }
                setFinPagina(nuevoFinPagina);
            }

            loadData();
        }
    };


    const loadData = async () => {
        setLoading(true);
        const response = await Api.ObtenerFormularios(options);
        if (!response.success) {
            setLoading(false);
            setError(true);
            return;
        }
        setData(response.data.datos);
        setPaginador(response.data);
        setLoading(false);
    }

    useEffect(() => {
        const dataUser = ParseJwt(localStorage.getItem('token'));
        setUser(dataUser);
        loadData().then();

        if (paginador?.pages) {
            setCantPaginas(paginador.pages);
        }

    }, [options]);

    return (
        <>
            <ToastContainer />
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Formularios</h1>
            </div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8">
                    <div className="card border-0 shadow-sm">
                        <div className="card-header border-0">
                            <h3>Buscar formulario</h3>
                            <div className="card-options">...</div>
                        </div>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-2"></div>
                                <div className="col-md-8">
                                    <form onSubmit={handleSubmit(handdleSearchCode)} >
                                        <div className="input-group mb-3">
                                            <button className="btn btn-primary btn-outline-secondary" type="button" id="button-addon2" name="Generar Código">
                                                <i className="bi bi-upc text-white"></i>
                                            </button>
                                            <input {...register("codigo", { required: "El codigo es obligatorio" })} placeholder="Codigo formulario" type="text" className={`form-control ${errors.codigo ? 'is-invalid' : ''}`} id="codigo" />
                                            <div className="invalid-feedback">
                                                {errors.codigo?.message}
                                            </div>
                                        </div>
                                        <div className="text-center mt-4">
                                            <button className="btn btn-primary text-center" type="submit">
                                                <i className="bi bi-search text-white"></i>
                                                &nbsp;Buscar
                                            </button>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-md-2"></div>
                            </div>
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                        </div>
                    </div>
                </div>
                <div className="col-md-2"></div>
            </div>
            <div className="row mt-5">
                <div className="col-md-12">
                    <div className="card border-0 shadow-sm">
                        <div className="card-body">
                            {error && <p style={{ color: 'red' }}>{error}</p>}
                            <div>
                                {
                                    loading ? (
                                        <Loading />
                                    ) : (
                                        <div className="table-responsive">
                                            <table className="table table-striped table-bordered my-1" style={{ fontSize: "13px" }}>
                                                <thead className="table-dark">
                                                    <tr>
                                                        <th scope="col">#</th>
                                                        <th scope="col">Codigo</th>
                                                        <th scope="col">Nombre</th>
                                                        <th scope="col">Telefono</th>
                                                        <th scope="col">Fecha creación</th>
                                                        <th scope="col" className='text-center'><i className="bi bi-view-list"></i></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {data.map((item, index) => (
                                                        <tr key={index}>
                                                            <td>{item.codigos.id}</td>
                                                            <td>{item.codigos.codigo}</td>
                                                            <td>{item.primerNombre} {item.segundoNombre} {item.primerApellido} {item.segundoApellido}</td>
                                                            <td>{item.telefono}</td>
                                                            <td>{format(new Date(item.fechaRegistro), 'dd/MM/yyyy')}</td>
                                                            <td className='text-center text-success'>
                                                                <Link to={`/dashboard/ver-formulario/${item.codigos.id}`}>
                                                                    <i className="bi bi-view-list fw-bold" style={{ cursor: 'pointer' }}></i>
                                                                </Link>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                            <div className="d-flex gap-2 justify-content-end">
                                                {
                                                    itemsPaginator(paginador?.pages).map((_, currentElementIndex) => (
                                                        <button
                                                            key={currentElementIndex}
                                                            className="btn btn-outline-primary rounded-circle p-0 paginator-button lh-1"
                                                            type="button"
                                                            onClick={() => cambiarPagina(paginador?.limit, currentElementIndex + 1)}
                                                        >
                                                            {currentElementIndex + 1}
                                                        </button>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
        </>
    )
}

export default Formularios;