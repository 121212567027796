import React from "react";

import '../../../../assets/style/dashboard.css';
import { Link } from "react-router-dom";
import logo from "../../../../assets/img/logo.png"

const Navbar = () => {
    return (
        <>
            <header className="navbar sticky-top bg-dark flex-md-nowrap p-2 shadow active" data-bs-theme="dark">
                <Link className="navbar-brand col-lg-2 me-0 px-3 text-white d-flex" to="/dashboard/home">
                    <img src={logo} width="30" height="30" alt=""></img>
                    <span className="d-flex">
                    &nbsp;Asilo a tu alcance
                    </span>
                </Link>

                <ul className="navbar-nav flex-row d-md-none">
                    {/* <li className="nav-item text-nowrap">
                        <button className="nav-link px-3 text-white" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSearch" aria-controls="navbarSearch" aria-expanded="false" aria-label="Toggle search">
                            <svg className="bi"><use xlink:href="#search" /></svg>
                        </button>
                    </li> */}
                    <li className="nav-item text-nowrap">
                        <button className="nav-link px-3 text-white" type="button" data-bs-toggle="offcanvas" data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </li>
                </ul>

                {/* <div id="navbarSearch" className="navbar-search w-100 collapse">
                    <input className="form-control w-100 rounded-0 border-0" type="text" placeholder="Search" aria-label="Search" />
                </div> */}
            </header>
        </>
    )
}

export default Navbar;