import React from 'react'

import { config } from "../../core/config/config";
import { Link } from 'react-router-dom';

const base_url_files = config.baseUrlFiles;

const DisplayDataLocal = ({ items, exceptions }) => {
    return (
        <tbody>
            {items.map((item, index) => (
                <tr key={`item-${index}`}>
                    <td className='align-middle'>{index + 1}</td>
                    {Object.entries(item).map(([key, value], valueIndex) => {
                        if (key === 'archivos' && Array.isArray(value) && value.length > 0) {
                            return (
                                <td key={`archivos-${index}-${valueIndex}`} className='align-middle'>
                                    {
                                        value.map((archivo, archivoIndex) => (
                                            <div className='mt-1' key={`archivo-${index}-${archivoIndex}`}>
                                                <span className='fw-bold'>{archivo.categoria}: </span>
                                                <Link to={`${base_url_files}/${archivo.nombreArchivo}`} target='_blank'>
                                                    {archivo.nombre}
                                                </Link>
                                            </div>
                                        ))
                                    }
                                </td>
                            );
                        } else {
                            if (!exceptions.includes(key)) {
                                return <td key={`value-${index}-${valueIndex}`} className='align-middle'>{value}</td>;
                            }
                        }
                    })}
                    {/* <td className='text-center align-middle text-danger'>
                        <i className="bi bi-trash fw-bold" onClick={() => handleDelete(item)} />
                    </td> */}
                </tr>
            ))}
        </tbody>
    );
};


export default DisplayDataLocal;
