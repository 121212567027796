import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import Navbar from './components/navbar/navbar';
import Sidebar from './components/navbar/sidebar';

function Dashboard() {

    useEffect(() => {
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';
    }, []);

    return (
        <>
            <Navbar />
            <div className="container-fluid content-page">
                <div className="row">
                    <Sidebar />
                    <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
                        <Outlet />
                    </main>
                </div>
            </div>
        </>
    );
}

export default Dashboard;