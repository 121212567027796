import React from "react";

import '../../../../assets/style/dashboard.css';
import { useUserAuth } from "../../../../core/providers/UserAuthContext";
import { NavLink } from "react-router-dom";
import logo from "../../../../assets/img/logo.png"

const Sidebar = () => {

    const { logOut } = useUserAuth();

    return (
        <>
            {/* bg-body-tertiary */}
            <div className="sidebar sidebaradmin col-md-3 col-lg-2 p-0">
                <div className="offcanvas-md offcanvas-end" tabIndex="-1" id="sidebarMenu" aria-labelledby="sidebarMenuLabel">
                    <div className="offcanvas-header">
                        <img src={logo} width="30" height="30" alt=""></img>
                        <h5 className="offcanvas-title text-white" id="sidebarMenuLabel">&nbsp;Asilo a tu alcance</h5>
                        <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="offcanvas" data-bs-target="#sidebarMenu" aria-label="Close"></button>
                    </div>
                    <div className="offcanvas-body d-md-flex flex-column p-0 pt-lg-3 vh-100 overflow-y-auto">
                        <ul className="nav flex-column">
                            <li className="nav-item">
                                <NavLink className={`nav-link d-flex align-items-center gap-2 ${(isActive) => (isActive ? 'active' : '')}`} aria-current="page" to="/dashboard/home">
                                    <i className="bi bi-house"></i>
                                    Inicio
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={`nav-link d-flex align-items-center gap-2 ${(isActive) => (isActive ? 'active' : '')}`} to="/dashboard/codigos">
                                    <i className="bi bi-upc"></i>
                                    Códigos
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className={`nav-link d-flex align-items-center gap-2 ${(isActive) => (isActive ? 'active' : '')}`} to="/dashboard/formularios">
                                    <i className="bi bi-card-list"></i>
                                    Formularios
                                </NavLink>
                            </li>
                        </ul>

                        <hr className="my-3" />

                        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mb-1 text-uppercase">
                            <span>Cuenta</span>
                            <a className="link-secondary" href="#" aria-label="Add a new report">
                            </a>
                        </h6>

                        <ul className="nav flex-column mb-auto">
                            <li className="nav-item">
                                <a className="nav-link d-flex align-items-center gap-2" href="#" onClick={logOut}>
                                    <i className="bi bi-box-arrow-left"></i>
                                    Cerrar sesion
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Sidebar;