import React from 'react';
import { NavLink, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import WOW from 'wowjs';

import residencia from '../../assets/img/residencia.jpg';
import paolamarca from '../../assets/img/equipo/paola-marca.jpg';
import paulacordoba from '../../assets/img/equipo/paula-cordoba.jpg';
import santiagovanegas from '../../assets/img/equipo/santiago-vanegas.jpg';

function Landing() {

    const [isScrolled, setIsScrolled] = useState(false);
    const [activeSection, setActiveSection] = useState("home");

    const sections = ["home", "sectionFive", "sectionSeven", "sectionEigth"];

    const handleScroll = () => {
        const scrollTop = window.scrollY;
        const threshold = 50;
        if (scrollTop > threshold) {
            setIsScrolled(true);
        } else {
            setIsScrolled(false);
        }

        const scrollPosition = window.scrollY + 100;

        sections.forEach((sectionId) => {
            const section = document.getElementById(sectionId);
            if (section) {
                const offsetTop = section.offsetTop;
                const sectionHeight = section.offsetHeight;

                if (scrollPosition >= offsetTop && scrollPosition < offsetTop + sectionHeight) {
                    setActiveSection(sectionId);
                }
            }
        });
    };

    useEffect(() => {
        new WOW.WOW().init();
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <div className="fixedArea">
                <nav className={`navbar myNavBar navbar-expand-lg navbar-dark bg-transparent ${isScrolled ? "active" : ""}`}>
                    <div className="container">
                        <a className="navbar-brand fs-4" href="#">Asilo a tu alcance</a>
                        <button className="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="sidebar offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header text-white border-bottom">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Asilo a tu alcance</h5>
                                <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-flex flex-column flex-lg-row p-4 p-lg-0">
                                <ul className="navbar-nav justify-content-center justify-content-center align-items-center flex-grow-1 pe-3" style={{ fontSize: '1.1em !important' }}>
                                    <li className="nav-item mx-2">
                                        <a className={`nav-link ${activeSection === 'home' ? "active" : ""}`} aria-current="page" href="#home">Inicio</a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <a className={`nav-link ${activeSection === 'sectionFive' ? "active" : ""}`} href="/#sectionFive">Beneficios</a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <a className={`nav-link ${activeSection === 'sectionSeven' ? "active" : ""}`} href="/#sectionSeven">Nuestro Equipo</a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <a className={`nav-link ${activeSection === 'sectionEigth' ? "active" : ""}`} href="/#sectionEigth">Preguntas Frecuentes</a>
                                    </li>
                                    <li className="nav-item mx-2">
                                        <NavLink className={`nav-link`} to={`/formulario`}>Formulario</NavLink>
                                    </li>
                                </ul>
                                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3">
                                    {/* <a href="#iniciar" className="text-white">Inciar sesion</a> */}
                                    <NavLink to={`/login`} target="_blank" className={`text-white text-decoration-none px-3 py-1 rounded-4`} style={{ backgroundColor: '#B1802D' }}>Iniciar sesión</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <header id="home" className="sectionOne w-100 d-flex flex-column justify-content-center align-items-center text-white fs-1 p-5">
                <h1 className="text-center mt-5 mb-3" style={{ fontSize: '2.8em', lineHeight: '0.8em' }}>En Asilo a tu <br />Alcance</h1>
                <h1 className="text-center mt-5 mb-5" style={{ fontSize: '1.2em', color: 'rgb(174, 183, 199)', lineHeight: '0.9em' }}>Te ayudamos a Cumplir el sueño de ser legal en Estados Unidos</h1>
                <a href="https://wa.me/16892589039?text=Hola, quiero mas información" target="_blank" className="text-white text-decoration-none px-3 py-2 rounded-4 mt-5 text-center wow bounce" style={{ fontSize: '0.5em', backgroundColor: '#B1802D' }}>
                    <i className="bi bi-whatsapp"></i> Agenda aquí tu consulta sin costo
                </a>
            </header>
            <section className="sectionTwo w-100 d-flex flex-column justify-content-center align-items-center fs-1 p-5 wow slideInLeft">
                <h1 className="fw-bold" style={{ fontSize: '1.2em' }}>¿Qué es el asilo?</h1>
                <h1 className="parrafo mt-5 mb-5t">
                    Es un programa internacional de protección que proporciona refugio y seguridad a personas que enfrentan persecución o peligro en sus países de origen. En los Estados Unidos, este recurso de protección está disponible para quienes temen ser perseguidos o sufrir daños debido a su raza, religión, nacionalidad, opiniones políticas o por pertenecer a un grupo social específico en sus países de origen.
                </h1>
            </section>
            <section className="sectionThree">
                <div className="parallax-mirror d-flex justify-content-center align-items-center">
                    <div className="cardInfo wow slideInRight">
                        <div className="contentCardInfo">
                            <h1 className="fw-bold" style={{ fontSize: '3.2em' }}>No importa cómo llegaste</h1>
                            <h1 className="parrafo fs-5 mt-3 mb-3">
                                Tienes opciones para legalizar tu situación migratoria en los Estados Unidos y nosotros te ayudamos en este proceso para que puedas disfrutar los beneficios que brinda un estatus legal.
                            </h1>
                        </div>
                    </div>
                </div>
            </section>
            <section className="sectionFour w-100 d-flex flex-column justify-content-center align-items-center fs-1 p-5">
                <h1 className="fw-bold text-white" style={{ fontSize: '1.2em' }}>¿Al solicitar asilo ya no me pueden deportar?</h1>
                <h1 className="parrafo text-white mt-5 mb-5">
                    Es un programa internacional de protección que proporciona refugio y seguridad a personas que enfrentan persecución o peligro en sus países de origen. En los Estados Unidos, este recurso de protección está disponible para quienes temen ser perseguidos o sufrir daños debido a su raza, religión, nacionalidad, opiniones políticas o por pertenecer a un grupo social específico en sus países de origen.
                </h1>
            </section>
            <section id="sectionFive" className="sectionFive w-100 fs-1 p-5">
                <div className="row">
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-start">
                        <h1 className="fw-bold" style={{ fontSize: '1.2em' }}>Beneficios de un Asilo</h1>
                        <h1 className="parrafo mt-5 mb-4">
                            Estos son algunos de los beneficios que obtienes al presentar tu proceso de asilo.
                        </h1>
                        <h1 className="parrafo mt-2">
                            <i className="bi bi-person-vcard-fill"></i> Permiso de trabajo.
                        </h1>
                        <h1 className="parrafo mt-3">
                            <i className="bi bi-credit-card-2-front-fill"></i> Tarjeta de Seguro Social.
                        </h1>
                        <h1 className="parrafo mt-3">
                            <i className="bi bi-wechat"></i> Enseñanza de un segundo idioma.
                        </h1>
                        <h1 className="parrafo mt-3">
                            <i className="bi bi-house-fill"></i> Poder solicitar la residencia permanente.
                        </h1>
                    </div>
                    <div className="col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <img className="rounded-4 wow bounceInDown" style={{ width: "90%" }} src={residencia} alt="Residencia EEUU" />
                    </div>
                </div>
            </section>
            <section className="sectionSix w-100 d-flex flex-column justify-content-center align-items-center fs-1 p-5">
                <h1 className="fw-bold text-white" style={{ fontSize: '1.2em' }}>Mereces una nueva vida sin miedos</h1>
                <h1 className="parrafo text-white mt-5 mb-5">
                    "Solicitar asilo puede parecer un proceso complejo, pero no estás solo. Aquí te explicamos los pasos que necesitas seguir y los recursos disponibles para ayudarte."
                </h1>
            </section>
            <section id="sectionSeven" className="sectionSeven w-100 d-flex flex-column justify-content-center align-items-center fs-1 p-5">
                <h1 className="fw-bold" style={{ fontSize: '1.2em' }}>Nuestro Equipo</h1>
                <h1 className="parrafo mt-5 mb-3 text-center">
                    Solos podemos hacer muy poco; juntos podemos conseguir mucho.<br />~ Helen Keller
                </h1>
                <div className="row">
                    <div className="col-md-4 p-4 d-flex justify-content-center align-items-center">
                        <div className="profile-card">
                            <div className="image-container">
                                <img src={paulacordoba} alt="Nombre de la persona" className="profile-image" />                            </div>
                            <div className="overlay">
                                <div className="text-container">
                                    <h2 className="text-white">Paula Cordoba</h2>
                                    <p className="text-white">Preparadora de Formas</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 p-4 d-flex justify-content-center align-items-center">
                        <div className="profile-card">
                            <div className="image-container">
                                <img src={paolamarca} alt="Nombre de la persona" className="profile-image" />                            </div>
                            <div className="overlay">
                                <div className="text-container">
                                    <h2 className="text-white">Paola Marca</h2>
                                    <p className="text-white">Agente de marketing</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 p-4 d-flex justify-content-center align-items-center">
                        <div className="profile-card">
                            <div className="image-container">
                                <img src={santiagovanegas} alt="Nombre de la persona" className="profile-image" />                            </div>
                            <div className="overlay">
                                <div className="text-container">
                                    <h2 className="text-white">Santiago Vanegas</h2>
                                    <p className="text-white">Agente Comercial</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="sectionEigth" className="sectionEigth w-100 d-flex flex-column justify-content-center align-items-center fs-1 p-5">
                <h1 className="fw-bold text-white" style={{ fontSize: '1.2em' }}>Preguntas Frecuentes</h1>
                <h1 className="parrafo mt-2 mb-5 text-center text-white">
                    Estas son algunas de las preguntas mas frecuentes.
                </h1>
                <div className="row w-100">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-auto">
                                <div className="footer-icon mt-2"></div>
                            </div>
                            <div className="col-md-10 col-sm-10 col-xs-10">
                                <h1 className="fw-bold text-white" style={{ fontSize: '0.7em' }}>¿Cuánto tiempo debo esperar para mi permiso de trabajo y social?</h1>
                                <h1 className="parrafo mt-4 mb-5 text-white" style={{ fontSize: '0.5em' }}>
                                    Una vez radicada la solicitud del asilo se debe esperar 180 días para solicitar estos documentos en los casos Defensivos y en los casos Afirmativos la persona es elegible a partir de los 150 días sin embargo migración dice que no se podrá fabricar su permiso de trabajo hasta completar los 180 días o tomar una decisión ante la solicitud.
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-auto">
                                <div className="footer-icon mt-2"></div>
                            </div>
                            <div className="col-md-10 col-sm-10 col-xs-10">
                                <h1 className="fw-bold text-white" style={{ fontSize: '0.7em' }}>¿Quién puede solicitar asilo?</h1>
                                <h1 className="parrafo mt-4 mb-5 text-white" style={{ fontSize: '0.5em' }}>
                                    El solicitante de asilo puede ser cualquier persona, independientemente de su género o nacionalidad. Además, tiene la opción de incluir en su solicitud a su cónyuge y a sus hijos solteros menores de 21 años, que estén dentro de Estados Unidos y  hayan ingresado bajo las mismas condiciones; a quienes se considera como "familia cercana".
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-auto">
                                <div className="footer-icon mt-2"></div>
                            </div>
                            <div className="col-md-10 col-sm-10 col-xs-10">
                                <h1 className="fw-bold text-white" style={{ fontSize: '0.7em' }}>¿Me deportan inmediatamente si mi asilo es denegado?</h1>
                                <h1 className="parrafo mt-4 mb-5 text-white" style={{ fontSize: '0.5em' }}>
                                    No siempre, existen varias opciones para apelar la decisión o solicitar una estancia legal dentro del pais.
                                </h1>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-auto">
                                <div className="footer-icon mt-2"></div>
                            </div>
                            <div className="col-md-10 col-sm-10 col-xs-10">
                                <h1 className="fw-bold text-white" style={{ fontSize: '0.7em' }}>¿Podré obtener mi residencia en algún momento?</h1>
                                <h1 className="parrafo mt-4 mb-5 text-white" style={{ fontSize: '0.5em' }}>
                                    Una vez es aprobada la solicitud de asilo tu estatus migratorio pasara a ser asilado, al año y un día de tener este estatus migratorio se podrá iniciar a solicitar la residencia permanente.
                                </h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <a href="https://wa.me/16892589039?text=Hola, quiero mas información" className="btn-wsp" target="_blank">
                <i className="bi bi-whatsapp icono"></i>
            </a>
        </>
    );
}

export default Landing;