import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'

import Landing from './pages/PagesLanding/landing';
import Form from './pages/PagesLanding/form';
import Dashboard from './pages/PagesDashboard/PagesDashboard';
import Login from './pages/Auth/login';
import { UserAuthContextProvider } from './core/providers/UserAuthContext';
import ProtectedRoute from './pages/protected/ProtectedRoute';
import Home from './pages/PagesDashboard/components/Home';
import Codigos from './pages/PagesDashboard/components/Codigos';
import Formularios from './pages/PagesDashboard/components/Formularios';
import FormularioDetalle from './pages/PagesDashboard/components/formulario/FormularioDetalle';

function App() {
    return (
        <UserAuthContextProvider>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Landing />} />
                    <Route path="/formulario" element={<Form />} />
                    <Route path="/login" element={<Login />} />
                    <Route path="/dashboard" element={
                        <ProtectedRoute>
                            <Dashboard />
                        </ProtectedRoute>
                    } >
                        <Route path="home" element={<Home />} />
                        <Route path="codigos" element={<Codigos />} />
                        <Route path="formularios" element={<Formularios />} />
                        <Route path="ver-formulario/:id" element={<FormularioDetalle />} />
                    </Route>
                </Routes>
            </BrowserRouter>
        </UserAuthContextProvider>
    );
}

export default App;