import React from 'react';
import { Navigate } from 'react-router-dom';
import { useUserAuth } from '../../core/providers/UserAuthContext';

function ProtectedRoute({ children }) {
    const { user, loading } = useUserAuth();

    if (loading) return <div>Loading...</div>;

    return user ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;