import React from 'react'
import { createContext, useContext, useEffect, useState } from "react";
import { config } from "../config/config";

const base_url = config.baseUrl;

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {

    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);

    const checkAuth = () => {
        const token = localStorage.getItem('token');
        if (token) {
            setUser(token);
        } else {
            setUser(null);
        }
        setLoading(false);
    };

    async function logIn(username, password) {

        const data = {
            username,
            password
        }

        try {
            const url = `${base_url}/auth/login`;
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            const result = await response.json();

            if (!response.ok) {
                let msj = result.message
                throw new Error(msj);
            }

            if (response.ok && result.token) {
                localStorage.setItem('token', result.token);
                setUser(result.token);
                return { success: true, token: result.token };
            } else {
                return { success: false, message: 'Error: Token no recibido' };
            }

        } catch (error) {
            return { success: false, message: error.message };
        }
    }

    function logOut() {
        localStorage.removeItem('token');
        setUser(null);
    }

    useEffect(() => {
        checkAuth();
    }, []);

    return (
        <userAuthContext.Provider value={{ user, logIn, logOut, loading }} >
            {!loading && children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}