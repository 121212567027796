import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './assets/style/main.css';
import './assets/style/whast.css';
import './assets/style/animate.css';

import 'bootstrap';

const root = createRoot(document.getElementById('root')); // Crear el root

console.log("La aplicación se está ejecutando");

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
); // Renderizar la aplicación