import React from 'react';

import { Link, NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { toast, ToastContainer } from 'react-toastify';
import Api from './services/FormServices'
import { config } from "../../core/config/config";

const base_url_files = config.baseUrlFiles;

import DisplayLocalStorageData from "../../core/utils/displayItems";

import logo from '../../assets/img/logo.png'
import 'react-toastify/dist/ReactToastify.css';

function Form() {

    const [progress, setProgress] = useState(0);
    const [validateCode, setValidateCode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showInfoMarital, setShowInfoMarital] = useState(false);
    const [showCantHijos, setShowCantHijos] = useState(false);
    const [cantHijos, setCantHijos] = useState(0);
    const [showStatus, setShowStatus] = useState(false);
    const [showInfoAdjuntos, setShowInfoAdjuntos] = useState(false);
    const [enusa, setEnusa] = useState([]);

    const { register: registerCode, handleSubmit: handleSubmitCode, reset: resetCode, formState: { errors: errorsCode } } = useForm();

    const storedCodigoFormulario = localStorage.getItem('codigoFormulario') || "";
    const storedItems = JSON.parse(localStorage.getItem('items')) || [];
    const storedItemsForm2 = JSON.parse(localStorage.getItem('items2')) || [];
    const storedItemsForm3 = JSON.parse(localStorage.getItem('items3')) || [];
    const storedItemsForm4 = JSON.parse(localStorage.getItem('items4')) || [];
    const storedItemsForm5 = JSON.parse(localStorage.getItem('items5')) || [];
    const storedItemsForm6 = JSON.parse(localStorage.getItem('items6')) || [];
    const storedItemsForm7 = JSON.parse(localStorage.getItem('items7')) || [];
    const storedItemsForm8 = JSON.parse(localStorage.getItem('items8')) || [];
    const storedItemsForm9 = JSON.parse(localStorage.getItem('items9')) || [];
    const storedItemsForm10 = JSON.parse(localStorage.getItem('items10')) || [];
    const storedItemsForm11 = JSON.parse(localStorage.getItem('items11')) || [];
    const storedItemsForm12 = JSON.parse(localStorage.getItem('items12')) || [];

    const [codigoFormulario, setCodigoFormulario] = useState(storedCodigoFormulario);
    const [items, setItems] = useState(storedItems);
    const [items2, setItems2] = useState(storedItemsForm2);
    const [items3, setItems3] = useState(storedItemsForm3);
    const [items4, setItems4] = useState(storedItemsForm4);
    const [items5, setItems5] = useState(storedItemsForm5);
    const [items6, setItems6] = useState(storedItemsForm6);
    const [items7, setItems7] = useState(storedItemsForm7);
    const [items8, setItems8] = useState(storedItemsForm8);
    const [items9, setItems9] = useState(storedItemsForm9);
    const [items10, setItems10] = useState(storedItemsForm10);
    const [items11, setItems11] = useState(storedItemsForm11);
    const [items12, setItems12] = useState(storedItemsForm12);

    const { register: registerForm1, handleSubmit: handleSubmitForm1, reset: reset1, formState: { errors: errors } } = useForm();
    const { register: registerForm2, handleSubmit: handleSubmitForm2, reset: reset2, formState: { errors: errors2 } } = useForm();
    const { register: registerForm3, handleSubmit: handleSubmitForm3, reset: reset3, formState: { errors: errors3 } } = useForm();
    const { register: registerForm4, handleSubmit: handleSubmitForm4, reset: reset4, formState: { errors: errors4 } } = useForm();
    const { register: registerForm5, handleSubmit: handleSubmitForm5, reset: reset5, formState: { errors: errors5 } } = useForm();
    const { register: registerForm6, handleSubmit: handleSubmitForm6, reset: reset6, formState: { errors: errors6 } } = useForm();
    const { register: registerForm7, handleSubmit: handleSubmitForm7, reset: reset7, watch, formState: { errors: errors7 } } = useForm();
    const { register: registerForm8, handleSubmit: handleSubmitForm8, reset: reset8, formState: { errors: errors8 } } = useForm();
    const { register: registerForm9, handleSubmit: handleSubmitForm9, reset: reset9, formState: { errors: errors9 } } = useForm();
    const { register: registerForm10, handleSubmit: handleSubmitForm10, reset: reset10, formState: { errors: errors10 } } = useForm();
    const { register: registerForm11, handleSubmit: handleSubmitForm11, reset: reset11, formState: { errors: errors11 } } = useForm();
    const { register: registerForm12, handleSubmit: handleSubmitForm12, reset: reset12, formState: { errors: errors12 } } = useForm();

    const onSubmitForm1 = async (data) => {

        const formData = new FormData();

        if (data.id[0]) formData.append('archivos', data.id[0], `id_${data.id[0].name}`);
        if (data.pasaporte[0]) formData.append('archivos', data.pasaporte[0], `pasaporte_${data.pasaporte[0].name}`);
        if (data.visa[0]) formData.append('archivos', data.visa[0], `visa_${data.visa[0].name}`);
        if (data.documentosFrontera[0]) formData.append('archivos', data.documentosFrontera[0], `documentosFrontera_${data.documentosFrontera[0].name}`);
        if (data.parole[0]) formData.append('archivos', data.parole[0], `parole_${data.parole[0].name}`);
        if (data.actaNacimiento[0]) formData.append('archivos', data.actaNacimiento[0], `actaNacimiento_${data.actaNacimiento[0].name}`);

        const response = await Api.SubirArchivos(formData);

        delete data.id;
        delete data.pasaporte;
        delete data.visa;
        delete data.documentosFrontera;
        delete data.parole;
        delete data.actaNacimiento;

        const newItem = {
            ...data,
            archivos: response.data.archivos || []
        };

        const newItems = [...items, newItem];

        setItems(newItems);
        localStorage.setItem('items', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset1();
    }

    const onSubmitForm2 = (data) => {
        const newItems = [...items2, data];
        setItems2(newItems);
        localStorage.setItem('items2', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset2();
    }

    const onSubmitForm3 = (data) => {
        const newItems = [...items3, data];
        setItems3(newItems);
        localStorage.setItem('items3', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset3();
    }

    const onSubmitForm4 = (data) => {
        const newItems = [...items4, data];
        setItems4(newItems);
        localStorage.setItem('items4', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset4();
    }

    const onSubmitForm5 = (data) => {
        const newItems = [...items5, data];
        setItems5(newItems);
        localStorage.setItem('items5', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setShowStatus(false)
        reset5();
    }

    const onSubmitForm6 = async (data) => {

        const formData = new FormData();

        if (data.id?.[0] && data.id[0]) formData.append('archivos', data.id[0], `id_${data.id[0].name}`);
        if (data.pasaporte?.[0] && data.pasaporte[0]) formData.append('archivos', data.pasaporte[0], `pasaporte_${data.pasaporte[0].name}`);
        if (data.visa?.[0] && data.visa[0]) formData.append('archivos', data.visa[0], `visa_${data.visa[0].name}`);
        if (data.documentosFrontera?.[0] && data.documentosFrontera[0]) formData.append('archivos', data.documentosFrontera[0], `documentosFrontera_${data.documentosFrontera[0].name}`);
        if (data.parole?.[0] && data.parole[0]) formData.append('archivos', data.parole[0], `parole_${data.parole[0].name}`);
        if (data.actaNacimiento?.[0] && data.actaNacimiento[0]) formData.append('archivos', data.actaNacimiento[0], `actaNacimiento_${data.actaNacimiento[0].name}`);
        if (data.actaMatrimonio?.[0] && data.actaMatrimonio[0]) formData.append('archivos', data.actaMatrimonio[0], `actaMatrimonio${data.actaMatrimonio[0].name}`);

        const response = await Api.SubirArchivos(formData);

        delete data.id;
        delete data.pasaporte;
        delete data.visa;
        delete data.documentosFrontera;
        delete data.parole;
        delete data.actaNacimiento;
        delete data.actaMatrimonio;

        const newItem = {
            ...data,
            archivos: response.data.archivos || []
        };

        const newItems = [...items6, newItem];

        setItems6(newItems);
        localStorage.setItem('items6', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset6();
    }

    const onSubmitForm7 = async (data) => {
        const cantidadHijos = data.cantidadhijos;

        var transformData;
        var newItems;
        if (cantHijos > 0) {
            transformData = await Promise.all(
                Array.from({ length: cantidadHijos }, async (_, index) => {
                    const formData = new FormData();

                    if (data.id[index]?.[0]) formData.append('archivos', data.id[index][0], `id_${data.id[index][0].name}`);
                    if (data.visa[index]?.[0]) formData.append('archivos', data.visa[index][0], `visa_${data.visa[index][0].name}`);
                    if (data.documentosFrontera[index]?.[0]) formData.append('archivos', data.documentosFrontera[index][0], `documentosFrontera_${data.documentosFrontera[index][0].name}`);
                    if (data.parole[index]?.[0]) formData.append('archivos', data.parole[index][0], `parole_${data.parole[index][0].name}`);
                    if (data.actaNacimiento[index]?.[0]) formData.append('archivos', data.actaNacimiento[index][0], `actaNacimiento_${data.actaNacimiento[index][0].name}`);
                    if (data.actaMatrimonio[index]?.[0]) formData.append('archivos', data.actaMatrimonio[index][0], `actaMatrimonio_${data.actaMatrimonio[index][0].name}`);

                    const response = await Api.SubirArchivos(formData);

                    delete data.id[index];
                    delete data.pasaporte[index];
                    delete data.visa[index];
                    delete data.documentosFrontera[index];
                    delete data.parole[index];
                    delete data.actaNacimiento[index];
                    delete data.actaMatrimonio[index];

                    const datos = {
                        tienehijos: data.tienehijos,
                        primernombrehijo: data.primernombrehijo[index],
                        segundonombrehijo: data.segundonombrehijo[index],
                        primerapellidohijo: data.primerapellidohijo[index],
                        segundoapellidohijo: data.segundoapellidohijo[index],
                        fechanacimientohijo: data.fechanacimientohijo[index],
                        ciudadnacimientohijo: data.ciudadnacimientohijo[index],
                        paisnacimientohijo: data.paisnacimientohijo[index],
                        nacionalidadhijo: data.nacionalidadhijo[index],
                        estadomaritalhijo: data.estadomaritalhijo[index],
                        enusa: data.enusa[index],
                        ciudadactualhijo: data.ciudadactualhijo[index],
                        paisactualhijo: data.paisactualhijo[index]
                    };

                    return {
                        ...datos,
                        archivos: response.data.archivos || []
                    };
                })
            );
            newItems = [...items7, ...transformData]
        } else {
            const datos = {
                tienehijos: data.tienehijos,
                primernombrehijo: "",
                segundonombrehijo: "",
                primerapellidohijo: "",
                segundoapellidohijo: "",
                fechanacimientohijo: "",
                ciudadnacimientohijo: "",
                paisnacimientohijo: "",
                nacionalidadhijo: "",
                estadomaritalhijo: "",
                enusa: "",
                ciudadactualhijo: "",
                paisactualhijo: "",
                archivos: []
            };
            newItems = [...items7, datos]
        }

        setItems7(newItems);
        localStorage.setItem('items7', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        setShowCantHijos(false);
        setCantHijos(0);
        setEnusa([]);
        reset7();
    };

    const onSubmitForm8 = (data) => {
        const newItems = [...items8, data];
        setItems8(newItems);
        localStorage.setItem('items8', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset8();
    }

    const onSubmitForm9 = (data) => {

        const newItems = [...items9, data];
        setItems9(newItems);
        localStorage.setItem('items9', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset9();
    }

    const onSubmitForm10 = (data) => {
        const newItems = [...items10, data];
        setItems10(newItems);
        localStorage.setItem('items10', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset10();
    }

    const onSubmitForm11 = (data) => {
        const newItems = [...items11, data];
        setItems11(newItems);
        localStorage.setItem('items11', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset11();
    }

    const onSubmitForm12 = (data) => {
        const newItems = [...items12, data];
        setItems12(newItems);
        localStorage.setItem('items12', JSON.stringify(newItems));

        toast.success("¡Registro guardado con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        reset12();
    }

    const handdleValidateCode = async (datos) => {
        setLoading(true);
        const response = await Api.ValidarCodigo(datos.codigo);
        if (!response.success) {
            setLoading(false);
            return;
        }

        setCodigoFormulario(datos.codigo);
        localStorage.setItem('codigoFormulario', datos.codigo);
        setValidateCode(true);
        setLoading(false);
    }

    const showInformacionMarital = (value) => {
        if (value === "casado/a") {
            setShowInfoMarital(true)
        } else {
            setShowInfoMarital(false)
        }
    }

    const showCantidadHijos = (value) => {
        if (value === "si") {
            setShowCantHijos(true)
        } else {
            setShowCantHijos(false)
            setCantHijos(0);
        }
    }

    const showEnUsa = (value) => {
        if (value === "si") {
            setShowInfoAdjuntos(true)
        } else {
            setShowInfoAdjuntos(false)
        }
    }

    const showEnUsaHijos = (index, value) => {
        setEnusa((prevItems) => {
            const newItems = [...prevItems];

            if (newItems[index]) {
                newItems[index].value = value;
            } else {
                newItems[index] = { index, value };
            }

            return newItems;
        });
        console.log(enusa)
    }

    const cantidadHijos = (e) => {
        const valor = parseInt(e.target.value, 10);
        if (!isNaN(valor)) {
            setCantHijos(valor);
        } else {
            setCantHijos(0);
        }
    };

    const calculateProgress = () => {
        const totalItems = 11;
        let filledItems = 0;

        for (let i = 1; i <= totalItems; i++) {
            let item = localStorage.getItem(`items${i}`);
            if (i == 1) {
                item = localStorage.getItem(`items`);
            }
            // else if (i == 10) {
            //     item = localStorage.getItem(`itemsFiles`);
            // }

            if (item && item !== "[]") {
                filledItems++;
            }
        }

        return Math.round((filledItems / totalItems) * 100);
    };

    const limpiarFormulario = async (e) => {
        const keysToRemove = ['items', 'items2', 'items3', 'items4', 'items5', 'items6', 'items7', 'items3', 'items8', 'items9', 'items10', 'items11', 'items12'];

        keysToRemove.forEach(async key => {

            /** REMOVE FILES */
            const res = localStorage.getItem(key);
            const itemsArray = res ? JSON.parse(res) : [];

            if (Array.isArray(itemsArray)) {
                await Promise.all(
                    itemsArray.map(async (item, index) => {
                        if (Array.isArray(item.archivos)) {
                            return Promise.all(
                                item.archivos.map(async (archivo, archivoIndex) => {
                                    await Api.BorrarArchivo(archivo.savedName);
                                })
                            );
                        }
                    })
                );
            }
            localStorage.removeItem(key);
            setProgress(0);
        });

        setItems([]);
        setItems2([]);
        setItems3([]);
        setItems4([]);
        setItems5([]);
        setItems6([]);
        setItems7([]);
        setItems8([]);
        setItems9([]);
        setItems10([]);
        setItems11([]);
        setItems12([]);

        toast.success("¡Registros eliminados con éxito!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });
    }

    const limpiarFormularioFinal = async (e) => {
        const keysToRemove = ['items', 'items2', 'items3', 'items4', 'items5', 'items6', 'items7', 'items3', 'items8', 'items9', 'items10', 'items11', 'items12'];

        keysToRemove.forEach(async key => {
            localStorage.removeItem(key);
            setProgress(0);
        });

        setItems([]);
        setItems2([]);
        setItems3([]);
        setItems4([]);
        setItems5([]);
        setItems6([]);
        setItems7([]);
        setItems8([]);
        setItems9([]);
        setItems10([]);
        setItems11([]);
        setItems12([]);
        setValidateCode(false);
        setCodigoFormulario("");
        resetCode();
    }

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const guadrarFormulario = async (e) => {
        setLoading(true);
        const codigoFormulario = localStorage.getItem("codigoFormulario");
        const informacionPersonal = localStorage.getItem("items");
        const direccionesResidencia = localStorage.getItem("items2");
        const estudios = localStorage.getItem("items3");
        const empleos = localStorage.getItem("items4");
        const padresHermanos = localStorage.getItem("items5");
        const informacionMarital = localStorage.getItem("items6");
        const informacionHijos = localStorage.getItem("items7");
        const salidaPais = localStorage.getItem("items8");
        const religion = localStorage.getItem("items9");
        const grupoEtnico = localStorage.getItem("items10");
        const idiomaNativo = localStorage.getItem("items11");
        const otrosIdiomas = localStorage.getItem("items12");

        const newData = {
            codigoFormulario,
            informacionPersonal,
            direccionesResidencia,
            estudios,
            empleos,
            padresHermanos,
            informacionMarital,
            informacionHijos,
            salidaPais,
            religion,
            grupoEtnico,
            idiomaNativo,
            otrosIdiomas
        }
        const response = await Api.guardarFormulario(newData);
        if (!response.success) {
            setLoading(false);
            return;
        }

        await limpiarFormularioFinal();
        setLoading(false);
    }

    const handdleCompareWord = (e) => {
        const buscar = e.target.value;
        const compare = "Estados Unidos";

        const res = buscar.localeCompare(compare, undefined, { sensitivity: 'base' })
        if (res == 0) {
            setShowStatus(true);
        } else {
            setShowStatus(false);
        }
    }

    useEffect(() => {
        /**Saber si es casado/a */
        const res = localStorage.getItem('items6');
        const itemsArray = res ? JSON.parse(res) : [];

        if (Array.isArray(itemsArray)) {
            itemsArray.map((item, index) => {
                if (item.estadomarital == "casado/a") {
                    setShowInfoMarital(true);
                }
            });
        }

        /**Calcular porcenje del formulario */
        const progressPercentage = calculateProgress();
        setProgress(progressPercentage);

        document.body.style.overflow = '';
        document.body.style.paddingRight = '';

    }, [items, items2, items3, items4, items5, items6, items7, items8, items9, items10, items11, items12, progress, showInfoMarital, enusa])


    return (
        <>
            <ToastContainer />
            <div className="fixedArea">
                <nav className={`navbar myNavBar navbar-expand-lg navbar-dark bg-transparent active`}>
                    <div className="container">
                        <NavLink className={`navbar-brand fs-4`} to={`/`}>Asilo a tu alcance</NavLink>
                        <button className="navbar-toggler shadow-none border-0" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="sidebar offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                            <div className="offcanvas-header text-white border-bottom">
                                <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Asilo a tu alcance</h5>
                                <button type="button" className="btn-close btn-close-white shadow-none" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                            </div>
                            <div className="offcanvas-body d-flex flex-column flex-lg-row p-4 p-lg-0 justify-content-end">
                                <div className="d-flex flex-column flex-lg-row justify-content-center align-items-center gap-3">
                                    <NavLink to={`/login`} target="_blank" className={`text-white text-decoration-none px-3 py-1 rounded-4`} style={{ backgroundColor: '#B1802D' }}>Iniciar sesión</NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            <section className="sectionForm">
                <div className="container">
                    {
                        validateCode ? (
                            <main className="pb-4 pt-5">
                                <div className="mt-5 mb-4 text-center">
                                    <img className="d-block mx-auto mb-4" src={logo} alt="" width="100" height="90" />
                                    <h2 className="fw-bold">Formulario de inscripción</h2>
                                    <p className="lead">Para dar continuidad con su proceso, es necesario que diligencie todos los campos del formulario.</p>
                                </div>
                                <div className="progress m-3">
                                    <div className="progress-bar progress-bar-striped progress-bar-animated fw-bold" role="progressbar" style={{ "width": `${progress}%`, "backgroundColor": "#B1802D" }} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100">{progress}%</div>
                                </div>
                                <div className="accordion shadow mt-4" id="accordionInformacion">
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseZero" aria-expanded="true" aria-controls="collapseZero">
                                                <span>1. Información personal</span>
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5" data-bs-toggle="tooltip" data-bs-placement="bottom" title="Sección completada"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseZero" className="accordion-collapse collapse show">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm1(onSubmitForm1)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>La siguiente información debe de ser del titular del proceso</p>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="primernombrepersonal" className="form-label">Primer nombre</label>
                                                                                <input {...registerForm1("primernombrepersonal", { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors.primernombrepersonal ? 'is-invalid' : ''}`} id="primernombrepersonal" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.primernombrepersonal?.message}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="segundonombrepersonal" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                                <input {...registerForm1("segundonombrepersonal")} type="text" className="form-control" id="segundonombrepersonal" placeholder="" defaultValue="" />
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="primerapellidopersonal" className="form-label">Primer apellido</label>
                                                                                <input {...registerForm1("primerapellidopersonal", { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors.primerapellidopersonal ? 'is-invalid' : ''}`} id="primerapellidopersonal" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.primerapellidopersonal?.message}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="segundoapellidopersonal" className="form-label">Segundo apellido</label>
                                                                                <input {...registerForm1("segundoapellidopersonal", { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors.segundoapellidopersonal ? 'is-invalid' : ''}`} id="segundoapellidopersonal" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.segundoapellidopersonal?.message}
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="ciudadnacimientopersonal" className="form-label">Ciudad de nacimiento</label>
                                                                                <input {...registerForm1("ciudadnacimientopersonal", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors.ciudadnacimientopersonal ? 'is-invalid' : ''}`} id="ciudadnacimientopersonal" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors.ciudadnacimientopersonal?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-3">
                                                                                <label htmlFor="numerocontactopersonal" className="form-label">Numero de telefono<span className="text-body-secondary">(De EEUU)</span></label>
                                                                                <div class="input-group mb-3">
                                                                                    <span class="input-group-text" id="basic-addon1">+51</span>
                                                                                    <input {...registerForm1("numerocontactopersonal", { required: "numero de telefono es obligatorio" })} type="text" className={`form-control ${errors.numerocontactopersonal ? 'is-invalid' : ''}`} id="numerocontactopersonal" placeholder="" aria-describedby="basic-addon1" />
                                                                                </div>
                                                                                <div className="invalid-feedback">
                                                                                    {errors.numerocontactopersonal?.message}
                                                                                </div>
                                                                            </div>
                                                                            <fieldset className="border rounded-3 mt-2 p-3">
                                                                                <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1.1rem" }} >Adjuntar archivos</legend>
                                                                                <div className="bd-callout bd-callout-info shadow-sm mt-0" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                    <h5>Tenga en cuenta</h5>
                                                                                    <p>Subir los documentos correspondientes que apliquen para su caso</p>
                                                                                </div>
                                                                                <div className='row'>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="id" className="form-label">ID</label>
                                                                                        <input {...registerForm1("id")} className={`form-control ${errors.cedula ? 'is-invalid' : ''}`} type="file" id="id" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.id?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="pasaporte" className="form-label">Pasaporte</label>
                                                                                        <input {...registerForm1("pasaporte")} className={`form-control ${errors.pasaporte ? 'is-invalid' : ''}`} type="file" id="pasaporte" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.pasaporte?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="visa" className="form-label">Visa</label>
                                                                                        <input {...registerForm1("visa")} className={`form-control ${errors.visa ? 'is-invalid' : ''}`} type="file" id="visa" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.visa?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="documentosFrontera" className="form-label">Documentos entregados en frontera</label>
                                                                                        <input {...registerForm1("documentosFrontera")} className={`form-control ${errors.cedula ? 'is-invalid' : ''}`} type="file" id="documentosfrontera" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.documentosFrontera?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="parole" className="form-label">parole</label>
                                                                                        <input {...registerForm1("parole")} className={`form-control ${errors.parole ? 'is-invalid' : ''}`} type="file" id="parole" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.parole?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="mb-3 col-sm-6">
                                                                                        <label htmlFor="actaNacimiento" className="form-label">Acta de nacimiento</label>
                                                                                        <input {...registerForm1("actaNacimiento")} className={`form-control ${errors.actaNacimiento ? 'is-invalid' : ''}`} type="file" id="actaNacimiento" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors.actaNacimiento?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </fieldset>
                                                                        </div>
                                                                        <hr className="my-4" />
                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Primer nombre</th>
                                                                                    <th scope="col">Segundo nombre</th>
                                                                                    <th scope="col">Primer apellido</th>
                                                                                    <th scope="col">Segundo apellido</th>
                                                                                    <th scope="col">Ciudad de nacimiento</th>
                                                                                    <th scope="col">Numero de telefono</th>
                                                                                    <th scope="col">Archivos</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items} setItems={setItems} localStorageItem={'items'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                2. Direcciones de residencia de los ultimos 5 años
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items2.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseOne" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                        <h5>Tenga en cuenta</h5>
                                                        <p>Proporcione la información de las direcciones en las que ha recidido en los ultimos 5 años, incluyendo las direcciones en Estados Unidos</p>
                                                    </div>
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm2(onSubmitForm2)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="col-sm-4">
                                                                    <label htmlFor="direccion" className="form-label">Dirección</label>
                                                                    <input {...registerForm2("direccion", { required: "La dirección es obligatoria" })} type="text" className={`form-control ${errors2.direccion ? 'is-invalid' : ''}`} id="direccion" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.direccion?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="numeroapartamento" className="form-label">Numero de Apartamento</label>
                                                                    <input {...registerForm2("numeroapartamento", { required: "El numero de apartamento es obligatorio" })} type="text" className={`form-control ${errors2.numeroapartamento ? 'is-invalid' : ''}`} id="numeroapartamento" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.numeroapartamento?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="ciudad" className="form-label">Ciudad</label>
                                                                    <input {...registerForm2("ciudad", { required: "La Ciudad es obligatoria" })} type="text" className={`form-control ${errors2.ciudad ? 'is-invalid' : ''}`} id="ciudad" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.ciudad?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="estadoprovincia" className="form-label">Estado/Provincia</label>
                                                                    <input {...registerForm2("estadoprovincia", { required: "El Estado/Provincia es obligatorio" })} type="text" className={`form-control ${errors2.estadoprovincia ? 'is-invalid' : ''}`} id="estadoprovincia" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.estadoprovincia?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="pais" className="form-label">Pais</label>
                                                                    <input {...registerForm2("pais", { required: "El pais es obligatorio" })} type="text" className={`form-control ${errors2.pais ? 'is-invalid' : ''}`} id="pais" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.pais?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-4">
                                                                    <label htmlFor="codigopostal" className="form-label">Codigo Postal</label>
                                                                    <input {...registerForm2("codigopostal", { required: "El codigo postal es obligatorio" })} type="text" className={`form-control ${errors2.codigopostal ? 'is-invalid' : ''}`} id="codigopostal" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors2.codigopostal?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de llegada</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesllegada" className="form-label">Mes</label>
                                                                            <select {...registerForm2("mesllegada", { required: "El mes de llegada es obligatorio" })} className={`form-select ${errors2.mesllegada ? 'is-invalid' : ''}`} id="mesllegada">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors2.mesllegada?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="aniollegada" className="form-label">Año</label>
                                                                            <input {...registerForm2("aniollegada", { required: "El año de llegada es obligatorio" })} type="number" className={`form-control ${errors2.aniollegada ? 'is-invalid' : ''}`} id="aniollegada" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors2.aniollegada?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de salida</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="messalida" className="form-label">Mes</label>
                                                                            <select {...registerForm2("messalida", { required: "El mes de salida es obligatorio" })} className={`form-select ${errors2.messalida ? 'is-invalid' : ''}`} id="messalida">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors2.messalida?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="aniosalida" className="form-label">Año</label>
                                                                            <input {...registerForm2("aniosalida", { required: "El año de salida es obligatorio" })} type="number" className={`form-control ${errors2.aniosalida ? 'is-invalid' : ''}`} id="aniosalida" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors2.aniosalida?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items2.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th colSpan={7}></th>
                                                                                    <th colSpan={2}>Fecha de llegada</th>
                                                                                    <th colSpan={2}>Fecha de salida</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Dirección</th>
                                                                                    <th scope="col">Número de Apartamento</th>
                                                                                    <th scope="col">Ciudad</th>
                                                                                    <th scope="col">Estado/Provincia</th>
                                                                                    <th scope="col">Pais</th>
                                                                                    <th scope="col">Codigo Postal</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items2} setItems={setItems2} localStorageItem={'items2'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                3. Estudios realizados y culminados
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items3.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwo" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm3(onSubmitForm3)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="institucion" className="form-label">Institución</label>
                                                                    <input {...registerForm3("institucion", { required: "La institución es obligatoria." })} type="text" className={`form-control ${errors3.institucion ? 'is-invalid' : ''}`} id="institucion" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors3.institucion?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="nivelacademico" className="form-label">Nivel academico</label>
                                                                    <select {...registerForm3("nivelacademico", { required: "El nivel academico es obligatorio" })} className={`form-select ${errors3.nivelacademico ? 'is-invalid' : ''}`} id="nivelacademico" required="">
                                                                        <option value="">Seleccione...</option>
                                                                        <option value="primaria">Primaria</option>
                                                                        <option value="secundaria">Secundaria</option>
                                                                        <option value="universidad">Universidad</option>
                                                                        <option value="tecnico">Tecnico</option>
                                                                        <option value="tecnologo">Tecnologo</option>
                                                                        <option value="especialización">Especialización</option>
                                                                        <option value="mestria">Mestria</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        {errors3.nivelacademico?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="ciudadestudio" className="form-label">Ciudad</label>
                                                                    <input {...registerForm3("ciudadestudio", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors3.ciudadestudio ? 'is-invalid' : ''}`} id="ciudadestudio" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors3.ciudadestudio?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="paisestudio" className="form-label">Pais</label>
                                                                    <input {...registerForm3("paisestudio", { required: "El pais es obligatorio" })} type="text" className={`form-control ${errors3.paisestudio ? 'is-invalid' : ''}`} id="paisestudio" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors3.paisestudio?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de inicio</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesinicio" className="form-label">Mes</label>
                                                                            <select {...registerForm3("mesinicio", { required: "El mes de inicio es obligatorio" })} className={`form-select ${errors3.mesinicio ? 'is-invalid' : ''}`} id="mesinicio">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors3.mesinicio?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="anioinicio" className="form-label">Año</label>
                                                                            <input {...registerForm3("anioinicio", { required: "El año de inicio es obligatorio" })} type="number" className={`form-control ${errors3.anioinicio ? 'is-invalid' : ''}`} id="anioinicio" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors3.anioinicio?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de culminación</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesculminacion" className="form-label">Mes</label>
                                                                            <select {...registerForm3("mesculminacion", { required: "El mes de culminacion es obligatorio" })} className={`form-select ${errors3.mesculminacion ? 'is-invalid' : ''}`} id="mesculminacion">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors3.mesculminacion?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="aniosculminacion" className="form-label">Año</label>
                                                                            <input {...registerForm3("aniosculminacion", { required: "El año de culminación es obligatorio" })} type="number" className={`form-control ${errors3.aniosculminacion ? 'is-invalid' : ''}`} id="aniosculminacion" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors3.aniosculminacion?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items3.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th colSpan={5}></th>
                                                                                    <th colSpan={2}>Fecha de inicio</th>
                                                                                    <th colSpan={2}>Fecha de culminación</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Institución</th>
                                                                                    <th scope="col">Nivel academico</th>
                                                                                    <th scope="col">Ciudad</th>
                                                                                    <th scope="col">Pais</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items3} setItems={setItems3} localStorageItem={'items3'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                4. Empleos de los ultimos 5 años
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items4.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseThree" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                        <h5>Tenga en cuenta</h5>
                                                        <p>Proporcione la información de los empleos que ha tenido en los ultimos 5 años, incluyendo los trabajos realizados en Estados Unidos, si aplica</p>
                                                    </div>
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm4(onSubmitForm4)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="col-sm-6">
                                                                    <label htmlFor="nombreempresa" className="form-label">Nombre de la empresa</label>
                                                                    <input {...registerForm4("nombreempresa", { required: "El nombre de la empresa es obligatorio." })} type="text" className={`form-control ${errors4.nombreempresa ? 'is-invalid' : ''}`} id="nombreempresa" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.nombreempresa?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="cargoocupacion" className="form-label">Cargo/Ocupación</label>
                                                                    <input {...registerForm4("cargoocupacion", { required: "El Cargo/Ocupación es obligatorio" })} type="text" className={`form-control ${errors4.cargoocupacion ? 'is-invalid' : ''}`} id="cargoocupacion" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.cargoocupacion?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="ciudadestudio" className="form-label">Ciudad</label>
                                                                    <input {...registerForm4("ciudadestudio", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors4.ciudadestudio ? 'is-invalid' : ''}`} id="ciudadestudio" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.ciudadestudio?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <label htmlFor="paisestudio" className="form-label">Pais</label>
                                                                    <input {...registerForm4("paisestudio", { required: "El pais es obligatorio" })} type="text" className={`form-control ${errors4.paisestudio ? 'is-invalid' : ''}`} id="paisestudio" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors4.paisestudio?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de inicio</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesinicioempleo" className="form-label">Mes</label>
                                                                            <select {...registerForm4("mesinicioempleo", { required: "El mes de inicio es obligatorio" })} className={`form-select ${errors4.mesinicioempleo ? 'is-invalid' : ''}`} id="mesinicioempleo">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors4.mesinicioempleo?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="anioinicioempleo" className="form-label">Año</label>
                                                                            <input {...registerForm4("anioinicioempleo", { required: "El año de inicio es obligatorio" })} type="number" className={`form-control ${errors4.anioinicioempleo ? 'is-invalid' : ''}`} id="anioinicioempleo" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors4.anioinicioempleo?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <h6 className="mb-3 fw-bold">Fecha de culminación</h6>
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <label htmlFor="mesculminacionempleo" className="form-label">Mes</label>
                                                                            <select {...registerForm4("mesculminacionempleo", { required: "El mes de culminacion es obligatorio" })} className={`form-select ${errors4.mesculminacionempleo ? 'is-invalid' : ''}`} id="mesculminacionempleo">
                                                                                <option value="">--Seleccione un mes--</option>
                                                                                <option value="01">Enero</option>
                                                                                <option value="02">Febrero</option>
                                                                                <option value="03">Marzo</option>
                                                                                <option value="04">Abril</option>
                                                                                <option value="05">Mayo</option>
                                                                                <option value="06">Junio</option>
                                                                                <option value="07">Julio</option>
                                                                                <option value="08">Agosto</option>
                                                                                <option value="09">Septiembre</option>
                                                                                <option value="10">Octubre</option>
                                                                                <option value="11">Noviembre</option>
                                                                                <option value="12">Diciembre</option>
                                                                            </select>
                                                                            <div className="invalid-feedback">
                                                                                {errors4.mesculminacionempleo?.message}
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <label htmlFor="anioculminacionempleo" className="form-label">Año</label>
                                                                            <input {...registerForm4("anioculminacionempleo", { required: "El año de culminación es obligatorio" })} type="number" className={`form-control ${errors4.anioculminacionempleo ? 'is-invalid' : ''}`} id="anioculminacionempleo" placeholder="" />
                                                                            <div className="invalid-feedback">
                                                                                {errors4.anioculminacionempleo?.message}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items4.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th colSpan={5}></th>
                                                                                    <th colSpan={2}>Fecha de inicio</th>
                                                                                    <th colSpan={2}>Fecha de culminación</th>
                                                                                    <th></th>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Nombre de la empresa</th>
                                                                                    <th scope="col">Cargo/Ocupación</th>
                                                                                    <th scope="col">Ciudad</th>
                                                                                    <th scope="col">Pais</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items4} setItems={setItems4} localStorageItem={'items4'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                5. Nombres completos de padres y  hermanos de mayor a menor
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items5.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseFour" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm5(onSubmitForm5)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            <div className="row g-3">
                                                                <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                    <h5>Tenga en cuenta</h5>
                                                                    <p>Proporcione la informacion de sus hermanos ordenados de mayor a menor, cuando aplique</p>
                                                                </div>
                                                                <div className="col-sm-12">
                                                                    <label htmlFor="parentezco" className="form-label">Parentezco</label>
                                                                    <select {...registerForm5("parentezco", { required: "El parentezco es obligatorio" })} className={`form-select ${errors5.parentezco ? 'is-invalid' : ''}`} id="parentezco" required="">
                                                                        <option value="">Seleccione...</option>
                                                                        <option value="papa">Papá</option>
                                                                        <option value="mama">Mamá </option>
                                                                        <option value="hermano">Hermano</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">
                                                                        {errors5.parentezco?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="primernombre" className="form-label">Primer nombre</label>
                                                                    <input {...registerForm5("primernombre", { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors5.primernombre ? 'is-invalid' : ''}`} id="primernombre" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.primernombre?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="segundonombre" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                    <input {...registerForm5("segundonombre")} type="text" className="form-control" id="segundonombre" placeholder="" defaultValue="" />
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="primerapellido" className="form-label">Primer apellido</label>
                                                                    <input {...registerForm5("primerapellido", { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors5.primerapellido ? 'is-invalid' : ''}`} id="primerapellido" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.primerapellido?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="segundoapellido" className="form-label">Segundo apellido</label>
                                                                    <input {...registerForm5("segundoapellido", { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors5.segundoapellido ? 'is-invalid' : ''}`} id="segundoapellido" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.segundoapellido?.message}
                                                                    </div>
                                                                </div>

                                                                <div className="col-sm-3">
                                                                    <label htmlFor="ciudadnacimiento" className="form-label">Ciudad de nacimiento</label>
                                                                    <input {...registerForm5("ciudadnacimiento", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors5.ciudadnacimiento ? 'is-invalid' : ''}`} id="ciudadnacimiento" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.ciudadnacimiento?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="paisnacimiento" className="form-label">Pais de nacimiento</label>
                                                                    <input {...registerForm5("paisnacimiento", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors5.paisnacimiento ? 'is-invalid' : ''}`} id="paisnacimiento" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.paisnacimiento?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="cidadactual" className="form-label">Ciudad donde vive actualmente</label>
                                                                    <input {...registerForm5("cidadactual", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors5.cidadactual ? 'is-invalid' : ''}`} id="cidadactual" placeholder="" />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.cidadactual?.message}
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-3">
                                                                    <label htmlFor="paisactual" className="form-label">Pais donde vive actualmente</label>
                                                                    <input {...registerForm5("paisactual", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors5.paisactual ? 'is-invalid' : ''}`} id="paisactual" placeholder="" onBlur={handdleCompareWord} />
                                                                    <div className="invalid-feedback">
                                                                        {errors5.paisactual?.message}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {
                                                                showStatus ? (
                                                                    <>
                                                                        <div className="bd-callout bd-callout-info shadow-sm mt-3" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                            <h5>Nota</h5>
                                                                            <p className='m-0'>Bajo que estatus migratorio se encuentra su familiar dentro de estados unidos</p>
                                                                        </div>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-3 m-0">
                                                                                <label htmlFor="statusmigratorio" className="form-label">Estatus migratorio?</label>
                                                                                <input {...registerForm5("statusmigratorio", { required: "El campo de status migratorio es obligtatorio" })} type="text" className={`form-control ${errors5.statusmigratorio ? 'is-invalid' : ''}`} id="statusmigratorio" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors5.statusmigratorio?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="row g-3"><div className="col-sm-3"></div></div>
                                                                )
                                                            }

                                                            <hr className="my-4" />
                                                            <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                    <span className="bi bi-plus h4 m-0"></span>
                                                                    <span>Agregar</span>
                                                                </button>
                                                            </div>
                                                            {
                                                                items5.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Parentezco</th>
                                                                                    <th scope="col">Primer nombre</th>
                                                                                    <th scope="col">Segundo nombre</th>
                                                                                    <th scope="col">Primer apellido</th>
                                                                                    <th scope="col">Segundo apellido</th>
                                                                                    <th scope="col">Ciudad de nacimiento</th>
                                                                                    <th scope="col">Pais de nacimiento</th>
                                                                                    <th scope="col">Ciudad donde vive actualmente</th>
                                                                                    <th scope="col">Pais donde vive actualmente</th>
                                                                                    <th scope="col">Status migratorio</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items5} setItems={setItems5} localStorageItem={'items5'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                6. Información marital
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items6.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseSix" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm6(onSubmitForm6)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items6.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-12">
                                                                                <label htmlFor="estadomarital" className="form-label">Estado marital</label>
                                                                                <select
                                                                                    {...registerForm6("estadomarital", { required: "El estado marital es obligatorio" })}
                                                                                    onChange={(e) => {
                                                                                        showInformacionMarital(e.target.value);
                                                                                        registerForm6("estadomarital").onChange(e);
                                                                                    }}
                                                                                    className={`form-select ${errors6.estadomarital ? 'is-invalid' : ''}`} id="estadomarital" required=""
                                                                                >
                                                                                    <option value="">Seleccione...</option>
                                                                                    <option value="soltero/a">Soltero/a</option>
                                                                                    <option value="casado/a">Casado/a</option>
                                                                                    <option value="divorciado/a">Divorciado/a</option>
                                                                                    <option value="viudo/a">Viudo/a</option>
                                                                                </select>
                                                                                <div className="invalid-feedback">
                                                                                    {errors6.estadomarital?.message}
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                showInfoMarital ? (
                                                                                    <>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="primernombremarital" className="form-label">Primer nombre</label>
                                                                                            <input {...registerForm6("primernombremarital", { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors6.primernombremarital ? 'is-invalid' : ''}`} id="primernombremarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.primernombremarital?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="segundonombremarital" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                                            <input {...registerForm6("segundonombremarital")} type="text" className="form-control" id="segundonombremarital" placeholder="" defaultValue="" />
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="primerapellidomarital" className="form-label">Primer apellido</label>
                                                                                            <input {...registerForm6("primerapellidomarital", { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors6.primerapellidomarital ? 'is-invalid' : ''}`} id="primerapellidomarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.primerapellidomarital?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="segundoapellidomarital" className="form-label">Segundo apellido</label>
                                                                                            <input {...registerForm6("segundoapellidomarital", { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors6.segundoapellidomarital ? 'is-invalid' : ''}`} id="segundoapellidomarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.segundoapellidomarital?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="fechanacimientomarital" className="form-label">Fecha de nacimiento</label>
                                                                                            <input {...registerForm6("fechanacimientomarital", { required: "La fecha de nacimiento es obligatoria" })} type="date" className={`form-control ${errors6.fechanacimientomarital ? 'is-invalid' : ''}`} id="fechanacimientomarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.fechanacimientomarital?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="ciudadnacimientomarital" className="form-label">Ciudad de nacimiento</label>
                                                                                            <input {...registerForm6("ciudadnacimientomarital", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors6.ciudadnacimientomarital ? 'is-invalid' : ''}`} id="ciudadnacimientomarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.ciudadnacimientomarital?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="paisnacimientomarital" className="form-label">Pais de nacimiento</label>
                                                                                            <input {...registerForm6("paisnacimientomarital", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors6.paisnacimientomarital ? 'is-invalid' : ''}`} id="paisnacimientomarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.paisnacimientomarital?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="nacionalidad" className="form-label">Nacionalidad</label>
                                                                                            <input {...registerForm6("nacionalidad", { required: "La nacionalidad es obligatoria" })} type="text" className={`form-control ${errors6.nacionalidad ? 'is-invalid' : ''}`} id="nacionalidad" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.nacionalidad?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="fechamatrimonio" className="form-label">Fecha de matrimonio</label>
                                                                                            <input {...registerForm6("fechamatrimonio", { required: "La fecha de matrimonio es obligatoria" })} type="date" className={`form-control ${errors6.fechamatrimonio ? 'is-invalid' : ''}`} id="fechamatrimonio" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.fechamatrimonio?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="ciudadmarital" className="form-label">Ciudad donde se casaron</label>
                                                                                            <input {...registerForm6("ciudadmarital", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors6.ciudadmarital ? 'is-invalid' : ''}`} id="ciudadmarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.ciudadmarital?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="paismarital" className="form-label">Pais donde se casaron</label>
                                                                                            <input {...registerForm6("paismarital", { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors6.paismarital ? 'is-invalid' : ''}`} id="paismarital" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.paismarital?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="enusa" className="form-label">Se encuentra en los Estados Unidos?</label>
                                                                                            <select
                                                                                                {...registerForm6("enusa", { required: "Debe seleccionar si se encuentra en los Estado Unidos" })}
                                                                                                onChange={(e) => {
                                                                                                    showEnUsa(e.target.value);
                                                                                                    registerForm6("enusa").onChange(e);
                                                                                                }}
                                                                                                className={`form-select ${errors6.enusa ? 'is-invalid' : ''}`} id="enusa" required=""
                                                                                            >
                                                                                                <option value="">Seleccione...</option>
                                                                                                <option value="si">Si</option>
                                                                                                <option value="no">No</option>
                                                                                            </select>
                                                                                            <div className="invalid-feedback">
                                                                                                {errors6.enusa?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        {
                                                                                            !showInfoAdjuntos ? (
                                                                                                <>
                                                                                                    <div className="col-sm-3">
                                                                                                        <label htmlFor="cidadactualmarital" className="form-label">Ciudad donde vive actualmente</label>
                                                                                                        <input {...registerForm6("cidadactualmarital", { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors6.cidadactualmarital ? 'is-invalid' : ''}`} id="cidadactualmarital" placeholder="" />
                                                                                                        <div className="invalid-feedback">
                                                                                                            {errors6.cidadactualmarital?.message}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-3">
                                                                                                        <label htmlFor="paisactualmarital" className="form-label">Pais donde vive actualmente</label>
                                                                                                        <input {...registerForm6("paisactualmarital", { required: "El pais donde vive actualmente es obligatorio" })} type="text" className={`form-control ${errors6.paisactualmarital ? 'is-invalid' : ''}`} id="paisactualmarital" placeholder="" onBlur={handdleCompareWord} />
                                                                                                        <div className="invalid-feedback">
                                                                                                            {errors6.paisactualmarital?.message}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <fieldset className="border rounded-3 mt-2 p-3">
                                                                                                    <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1.1rem" }} >Adjuntar archivos</legend>
                                                                                                    <div className="bd-callout bd-callout-info shadow-sm mt-0" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                                        <h5>Tenga en cuenta</h5>
                                                                                                        <p>Subir los documentos correspondientes que apliquen para su caso</p>
                                                                                                    </div>
                                                                                                    <div className='row'>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="id" className="form-label">ID</label>
                                                                                                            <input {...registerForm6("id")} className={`form-control ${errors6.cedula ? 'is-invalid' : ''}`} type="file" id="id" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.id?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="pasaporte" className="form-label">Pasaporte</label>
                                                                                                            <input {...registerForm6("pasaporte")} className={`form-control ${errors6.pasaporte ? 'is-invalid' : ''}`} type="file" id="pasaporte" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.pasaporte?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="visa" className="form-label">Visa</label>
                                                                                                            <input {...registerForm6("visa")} className={`form-control ${errors6.visa ? 'is-invalid' : ''}`} type="file" id="visa" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.visa?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="documentosFrontera" className="form-label">Documentos entregados en frontera</label>
                                                                                                            <input {...registerForm6("documentosFrontera")} className={`form-control ${errors6.cedula ? 'is-invalid' : ''}`} type="file" id="documentosfrontera" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.documentosFrontera?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="parole" className="form-label">parole</label>
                                                                                                            <input {...registerForm6("parole")} className={`form-control ${errors6.parole ? 'is-invalid' : ''}`} type="file" id="parole" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.parole?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="actaNacimiento" className="form-label">Acta de nacimiento</label>
                                                                                                            <input {...registerForm6("actaNacimiento")} className={`form-control ${errors6.actaNacimiento ? 'is-invalid' : ''}`} type="file" id="actaNacimiento" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.actaNacimiento?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="mb-3">
                                                                                                            <label htmlFor="actaMatrimonio" className="form-label">Acta de matrimonio</label>
                                                                                                            <input {...registerForm6("actaMatrimonio")} className={`form-control ${errors6.actaMatrimonio ? 'is-invalid' : ''}`} type="file" id="actaMatrimonio" />
                                                                                                            <div className="invalid-feedback">
                                                                                                                {errors6.actaMatrimonio?.message}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </fieldset>
                                                                                            )
                                                                                        }
                                                                                    </>
                                                                                ) : (<></>)
                                                                            }

                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items6.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Estado marital</th>
                                                                                    <th scope="col">Primer nombre</th>
                                                                                    <th scope="col">Segundo nombre</th>
                                                                                    <th scope="col">Primer apellido</th>
                                                                                    <th scope="col">Segundo apellido</th>
                                                                                    <th scope="col">Fecha de nacimiento</th>
                                                                                    <th scope="col">Ciudad de nacimiento</th>
                                                                                    <th scope="col">Pais de nacimiento</th>
                                                                                    <th scope="col">Nacionalidad</th>
                                                                                    <th scope="col">Fecha de matrimonio</th>
                                                                                    <th scope="col">Ciudad donde se casaron</th>
                                                                                    <th scope="col">Pais donde se casaron</th>
                                                                                    <th scope="col">En USA</th>
                                                                                    <th scope="col">Ciudad Actual</th>
                                                                                    <th scope="col">Pais Actual</th>
                                                                                    <th scope="col">Archivos</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items6} setItems={setItems6} localStorageItem={'items6'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEigth" aria-expanded="false" aria-controls="collapseEigth">
                                                7. Información hijos
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items7.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseEigth" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm7(onSubmitForm7)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items7.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-6">
                                                                                <label htmlFor="tienehijos" className="form-label">Tiene hijos?</label>
                                                                                <select
                                                                                    {...registerForm7("tienehijos", { required: "Debe seleccionar si tiene hijos" })}
                                                                                    onChange={(e) => {
                                                                                        showCantidadHijos(e.target.value);
                                                                                        registerForm7("tienehijos").onChange(e);
                                                                                    }}
                                                                                    className={`form-select ${errors7.tienehijos ? 'is-invalid' : ''}`} id="tienehijos" required=""
                                                                                >
                                                                                    <option value="">Seleccione...</option>
                                                                                    <option value="si">Si</option>
                                                                                    <option value="no">No</option>
                                                                                </select>
                                                                                <div className="invalid-feedback">
                                                                                    {errors7.tienehijos?.message}
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                showCantHijos ? (
                                                                                    <div className="col-sm-6">
                                                                                        <label htmlFor="cantidadhijos" className="form-label">Cuantos Hijos?</label>
                                                                                        <input {...registerForm7("cantidadhijos", { required: "El campo hijos es obligatorio" })} onBlur={cantidadHijos} type="number" className={`form-control ${errors7.cantidadhijos ? 'is-invalid' : ''}`} id="cantidadhijos" placeholder="" />
                                                                                        <div className="invalid-feedback">
                                                                                            {errors7.cantidadhijos?.message}
                                                                                        </div>
                                                                                    </div>
                                                                                ) : (
                                                                                    <div className="col-sm-6"></div>
                                                                                )
                                                                            }
                                                                        </div>
                                                                        {
                                                                            [...Array(cantHijos)].map((_, i) => (
                                                                                <fieldset className="border rounded-3 mt-2 p-3" key={i}>
                                                                                    <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1rem" }} >Información hijo #{i + 1}</legend>
                                                                                    <div className="row g-3">
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="primernombrehijo" className="form-label">Primer nombre</label>
                                                                                            <input {...registerForm7(`primernombrehijo[${i}]`, { required: "El primer nombre es obligatorio" })} type="text" className={`form-control ${errors7.primernombrehijo?.[i] && errors9.primernombrehijo[i] ? 'is-invalid' : ''}`} id="primernombrehijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.primernombrehijo?.[i] && errors7.primernombrehijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="segundonombrehijo" className="form-label">Segundo nombre<span className="text-body-secondary">(Si aplica)</span></label>
                                                                                            <input {...registerForm7(`segundonombrehijo[${i}]`)} type="text" className="form-control" id="segundonombrehijo" placeholder="" defaultValue="" />
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="primerapellidohijo" className="form-label">Primer apellido</label>
                                                                                            <input {...registerForm7(`primerapellidohijo[${i}]`, { required: "El primer apellido es obligatorio" })} type="text" className={`form-control ${errors7.primerapellidohijo?.[i] && errors7.primerapellidohijo[i] ? 'is-invalid' : ''}`} id="primerapellidohijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.primerapellidohijo?.[i] && errors7.primerapellidohijo?.[i] && errors7.primerapellidohijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="segundoapellidohijo" className="form-label">Segundo apellido</label>
                                                                                            <input {...registerForm7(`segundoapellidohijo[${i}]`, { required: "El segundo apellido es obligatorio" })} type="text" className={`form-control ${errors7.segundoapellidohijo?.[i] && errors9.segundoapellidohijo[i] ? 'is-invalid' : ''}`} id="segundoapellidohijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.segundoapellidohijo?.[i] && errors7.segundoapellidohijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="fechanacimientohijo" className="form-label">Fecha de nacimiento</label>
                                                                                            <input {...registerForm7(`fechanacimientohijo[${i}]`, { required: "La fecha de nacimiento es obligatoria" })} type="date" className={`form-control ${errors7.fechanacimientohijo?.[i] && errors7.fechanacimientohijo[i] ? 'is-invalid' : ''}`} id="fechanacimientohijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.fechanacimientohijo?.[i] && errors7.fechanacimientohijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="ciudadnacimientohijo" className="form-label">Ciudad de nacimiento</label>
                                                                                            <input {...registerForm7(`ciudadnacimientohijo[${i}]`, { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors7.ciudadnacimientohijo?.[i] && errors7.ciudadnacimientohijo[i] ? 'is-invalid' : ''}`} id="ciudadnacimientohijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.ciudadnacimientohijo?.[i] && errors7.ciudadnacimientohijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="paisnacimientohijo" className="form-label">Pais de nacimiento</label>
                                                                                            <input {...registerForm7(`paisnacimientohijo[${i}]`, { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors7.paisnacimientohijo?.[i] && errors7.paisnacimientohijo[i] ? 'is-invalid' : ''}`} id="paisnacimientohijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.paisnacimientohijo?.[i] && errors7.paisnacimientohijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="nacionalidadhijo" className="form-label">Nacionalidad</label>
                                                                                            <input {...registerForm7(`nacionalidadhijo[${i}]`, { required: "La nacionalidad es obligatoria" })} type="text" className={`form-control ${errors7.nacionalidadhijo?.[i] && errors7.nacionalidadhijo[i] ? 'is-invalid' : ''}`} id="nacionalidadhijo" placeholder="" />
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.nacionalidadhijo?.[i] && errors7.nacionalidadhijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="estadomaritalhijo" className="form-label">Estado marital</label>
                                                                                            <select {...registerForm7(`estadomaritalhijo[${i}]`, { required: "El estado marital es obligatorio" })} className={`form-select ${errors7.estadomaritalhijo?.[i] && errors7.estadomaritalhijo[i] ? 'is-invalid' : ''}`} id="estadomaritalhijo" required="">
                                                                                                <option value="">Seleccione...</option>
                                                                                                <option value="soltero/a">Soltero/a</option>
                                                                                                <option value="casado/a">Casado/a</option>
                                                                                                <option value="divorciado/a">Divorciado/a</option>
                                                                                                <option value="viudo/a">Viudo/a</option>
                                                                                            </select>
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.estadomaritalhijo?.[i] && errors7.estadomaritalhijo[i]?.message}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-3">
                                                                                            <label htmlFor="enusa" className="form-label">Se encuentra en los Estados Unidos?</label>
                                                                                            <select
                                                                                                {...registerForm7(`enusa[${i}]`, { required: "Debe seleccionar si se encuentra en los Estado Unidos" })}
                                                                                                onChange={(e) => {
                                                                                                    showEnUsaHijos(i, e.target.value);
                                                                                                    registerForm7(`enusa[${i}]`).onChange(e);
                                                                                                }}
                                                                                                className={`form-select ${errors7.enusa?.[i] && errors7.enusa[i] ? 'is-invalid' : ''}`} id={`enusa-${i}`} required=""
                                                                                            >
                                                                                                <option value="">Seleccione...</option>
                                                                                                <option value="si">Si</option>
                                                                                                <option value="no">No</option>
                                                                                            </select>
                                                                                            <div className="invalid-feedback">
                                                                                                {errors7.enusa?.[i] && errors7.enusa[i]?.message}
                                                                                            </div>
                                                                                        </div>

                                                                                        {
                                                                                            enusa?.[i] && enusa[i].value === "no" ? (
                                                                                                <>
                                                                                                    <div className="col-sm-3">
                                                                                                        <label htmlFor="ciudadactualhijo" className="form-label">Ciudad donde vive actualmente</label>
                                                                                                        <input {...registerForm7(`ciudadactualhijo[${i}]`, { required: "La ciudad es obligatoria" })} type="text" className={`form-control ${errors7.ciudadactualhijo?.[i] && errors7.ciudadactualhijo[i] ? 'is-invalid' : ''}`} id="ciudadactualhijo" placeholder="" />
                                                                                                        <div className="invalid-feedback">
                                                                                                            {errors7.ciudadactualhijo?.[i] && errors7.ciudadactualhijo[i]?.message}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col-sm-3">
                                                                                                        <label htmlFor="paisactualhijo" className="form-label">Pais donde vive actualmente</label>
                                                                                                        <input {...registerForm7(`paisactualhijo[${i}]`, { required: "El pais de nacimiento es obligatorio" })} type="text" className={`form-control ${errors7.paisactualhijo?.[i] && errors7.paisactualhijo[i] ? 'is-invalid' : ''}`} id="paisactualhijo" placeholder="" />
                                                                                                        <div className="invalid-feedback">
                                                                                                            {errors7.paisactualhijo?.[i] && errors7.paisactualhijo[i]?.message}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </>
                                                                                            ) : (
                                                                                                <fieldset className="border rounded-3 mt-2 p-3">
                                                                                                    <legend className="float-none w-auto px-3 fw-bold" style={{ "fontSize": "1.1rem" }} >Adjuntar archivos</legend>
                                                                                                    <div className="bd-callout bd-callout-info shadow-sm mt-0" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                                        <h5>Tenga en cuenta</h5>
                                                                                                        <p>Subir los documentos correspondientes que apliquen para su caso</p>
                                                                                                    </div>
                                                                                                    <div className='row'>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="id" className="form-label">ID</label>
                                                                                                            <input {...registerForm7(`id[${i}]`)} className={`form-control`} type="file" id="id" />
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="pasaporte" className="form-label">Pasaporte</label>
                                                                                                            <input {...registerForm7(`pasaporte[${i}]`)} className={`form-control`} type="file" id="pasaporte" />
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="visa" className="form-label">Visa</label>
                                                                                                            <input {...registerForm7(`visa[${i}]`)} className={`form-control`} type="file" id="visa" />
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="documentosFrontera" className="form-label">Documentos entregados en frontera</label>
                                                                                                            <input {...registerForm7(`documentosFrontera[${i}]`)} className={`form-control`} type="file" id="documentosfrontera" />
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="parole" className="form-label">parole</label>
                                                                                                            <input {...registerForm7(`parole[${i}]`)} className={`form-control`} type="file" id="parole" />
                                                                                                        </div>
                                                                                                        <div className="mb-3 col-sm-6">
                                                                                                            <label htmlFor="actaNacimiento" className="form-label">Acta de nacimiento</label>
                                                                                                            <input {...registerForm7(`actaNacimiento[${i}]`)} className={`form-control`} type="file" id="actaNacimiento" />
                                                                                                        </div>
                                                                                                        <div className="mb-3">
                                                                                                            <label htmlFor="actaMatrimonio" className="form-label">Acta de matrimonio</label>
                                                                                                            <input {...registerForm7(`actaMatrimonio[${i}]`)} className={`form-control`} type="file" id="actaMatrimonio" />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </fieldset>
                                                                                            )
                                                                                        }
                                                                                    </div>
                                                                                </fieldset>
                                                                            ))
                                                                        }
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                        </form>
                                                        {
                                                            items7.length > 0 && (
                                                                <div className="table-responsive">
                                                                    <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                        <thead className="table-dark">
                                                                            <tr>
                                                                                <th scope="col">#</th>
                                                                                <th scope="col">Hijos</th>
                                                                                <th scope="col">Primer nombre</th>
                                                                                <th scope="col">Segundo nombre</th>
                                                                                <th scope="col">Primer apellido</th>
                                                                                <th scope="col">Segundo apellido</th>
                                                                                <th scope="col">Fecha de nacimiento</th>
                                                                                <th scope="col">Ciudad de nacimiento</th>
                                                                                <th scope="col">Pais de nacimiento</th>
                                                                                <th scope="col">Nacionalidad</th>
                                                                                <th scope="col">Estado marital</th>
                                                                                <th scope="col">En USA</th>
                                                                                <th scope="col">Ciudad donde vive</th>
                                                                                <th scope="col">Pais donde vive</th>
                                                                                <th scope="col">Archivos</th>
                                                                                <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <DisplayLocalStorageData items={items7} setItems={setItems7} localStorageItem={'items7'} />
                                                                    </table>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                8. Fecha de salida del pais
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items8.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseFive" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm8(onSubmitForm8)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items8.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="diasalida" className="form-label">Dia</label>
                                                                                <input {...registerForm8("diasalida", { required: "El dia de salida del pais es obligatorio" })} type="number" className={`form-control ${errors8.diasalida ? 'is-invalid' : ''}`} id="diasalida" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors8.diasalida?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="messalidapais" className="form-label">Mes</label>
                                                                                <select {...registerForm8("messalidapais", { required: "El mes de salida del pais es obligatorio" })} className={`form-select ${errors8.messalidapais ? 'is-invalid' : ''}`} id="messalidapais">
                                                                                    <option value="">--Seleccione un mes--</option>
                                                                                    <option value="01">Enero</option>
                                                                                    <option value="02">Febrero</option>
                                                                                    <option value="03">Marzo</option>
                                                                                    <option value="04">Abril</option>
                                                                                    <option value="05">Mayo</option>
                                                                                    <option value="06">Junio</option>
                                                                                    <option value="07">Julio</option>
                                                                                    <option value="08">Agosto</option>
                                                                                    <option value="09">Septiembre</option>
                                                                                    <option value="10">Octubre</option>
                                                                                    <option value="11">Noviembre</option>
                                                                                    <option value="12">Diciembre</option>
                                                                                </select>
                                                                                <div className="invalid-feedback">
                                                                                    {errors8.messalidapais?.message}
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="aniosalidapais" className="form-label">Año</label>
                                                                                <input {...registerForm8("aniosalidapais", { required: "El año de salida del pais es obligatorio" })} type="number" className={`form-control ${errors8.aniosalidapais ? 'is-invalid' : ''}`} id="aniosalidapais" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors8.aniosalidapais?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />
                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items8.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Dia</th>
                                                                                    <th scope="col">Mes</th>
                                                                                    <th scope="col">Año</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items8} setItems={setItems8} localStorageItem={'items8'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                9. Religión
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items9.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseSeven" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm9(onSubmitForm9)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items9.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="religion" className="form-label">Religión</label>
                                                                                <input {...registerForm9("religion", { required: "La religión es obligatoria" })} type="text" className={`form-control ${errors9.religion ? 'is-invalid' : ''}`} id="religion" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors9.religion?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items9.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Religión</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items9} setItems={setItems9} localStorageItem={'items9'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTen" aria-expanded="false" aria-controls="collapseTen">
                                                10. Grupo Etnico
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items10.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTen" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm10(onSubmitForm10)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items10.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="grupoetnico" className="form-label">Grupo etnico</label>
                                                                                <input {...registerForm10("grupoetnico", { required: "El grupo etnico es obligatorio" })} type="text" className={`form-control ${errors10.grupoetnico ? 'is-invalid' : ''}`} id="grupoetnico" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors10.religion?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items10.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Grupo etnico</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items10} setItems={setItems10} localStorageItem={'items10'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseEleven" aria-expanded="false" aria-controls="collapseEleven">
                                                11. Idioma nativo
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items11.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseEleven" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm11(onSubmitForm11)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items11.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>Indique cual es su idioma de nacimiento</p>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="idiomanativo" className="form-label">Idioma nativo</label>
                                                                                <input {...registerForm11("idiomanativo", { required: "El idioma nativo es obligatorio" })} type="text" className={`form-control ${errors11.idiomanativo ? 'is-invalid' : ''}`} id="idiomanativo" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors11.idiomanativo?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items11.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Idioma nativo</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items11} setItems={setItems11} localStorageItem={'items11'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-item">
                                        <h2 className="accordion-header">
                                            <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwelve" aria-expanded="false" aria-controls="collapseTwelve">
                                                12. otro idioma
                                                <span className="ms-auto d-flex align-items-center">
                                                    {
                                                        items12.length > 0 && (
                                                            <span className="bi bi-check-circle-fill text-gold fs-5"></span>
                                                        )
                                                    }
                                                </span>
                                            </button>
                                        </h2>
                                        <div id="collapseTwelve" className="accordion-collapse collapse">
                                            <div className="accordion-body">
                                                <div className="row g-3">
                                                    <div className="col-md-12 col-lg-12">
                                                        <form onSubmit={handleSubmitForm12(onSubmitForm12)} className="needs-validation" noValidate="" onKeyDown={handleKeyDown}>
                                                            {
                                                                items12.length == 0 && (
                                                                    <>
                                                                        <div className="row g-3">
                                                                            <div className="bd-callout bd-callout-info shadow-sm" style={{ "borderLeftColor": "#B1802D", "padding": "0.6rem" }}>
                                                                                <h5>Tenga en cuenta</h5>
                                                                                <p>Indique si habla un segundo idioma con fluidez, si aplica</p>
                                                                            </div>
                                                                            <div className="col-sm-4">
                                                                                <label htmlFor="otroidioma" className="form-label">Otro idioma</label>
                                                                                <input {...registerForm12("otroidioma")} type="text" className={`form-control ${errors12.otroidioma ? 'is-invalid' : ''}`} id="otroidioma" placeholder="" />
                                                                                <div className="invalid-feedback">
                                                                                    {errors12.otroidioma?.message}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <hr className="my-4" />

                                                                        <div className="text-center" style={{ "justifyItems": "center" }}>
                                                                            <button className="btn btn-primary btn-sm d-flex align-items-center" type="submit">
                                                                                <span className="bi bi-plus h4 m-0"></span>
                                                                                <span>Agregar</span>
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }
                                                            {
                                                                items12.length > 0 && (
                                                                    <div className="table-responsive">
                                                                        <table className="table table-striped table-bordered my-4" style={{ fontSize: "13px" }}>
                                                                            <thead className="table-dark">
                                                                                <tr>
                                                                                    <th scope="col">#</th>
                                                                                    <th scope="col">Otro idioma</th>
                                                                                    <th scope="col" className='text-center'><i className="bi bi-trash"></i></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <DisplayLocalStorageData items={items12} setItems={setItems12} localStorageItem={'items12'} />
                                                                        </table>
                                                                    </div>
                                                                )
                                                            }
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="text-center mt-4">
                                    <button className="btn btn-danger btn-lg text-center" style={{ "fontSize": "1rem" }} onClick={limpiarFormulario}>
                                        <span className='bi bi-trash'></span>
                                        &nbsp;Limpiar formulario
                                    </button>&nbsp;
                                    {
                                        progress == 100 ? (
                                            loading ? (
                                                <button className="btn btn-success btn-lg text-center" style={{ "fontSize": "1rem" }} disabled>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp; Guardando...
                                                </button>
                                            ) : (
                                                <button className="btn btn-success btn-lg text-center" style={{ "fontSize": "1rem" }} onClick={guadrarFormulario}>
                                                    <span className='bi bi-floppy-fill'></span>
                                                    &nbsp;Terminar formulario
                                                </button>
                                            )
                                        ) : (
                                            <button className="btn btn-secondary btn-lg text-center" style={{ "fontSize": "1rem" }} disabled>
                                                <span className='bi bi-floppy-fill'></span>
                                                &nbsp;Terminar formulario
                                            </button>
                                        )
                                    }
                                </div>

                            </main>
                        ) : (
                            <main className="vh-100 pb-5 pt-5 d-flex flex-column flex-lg-row justify-content-center align-items-center">
                                <div className="col-md-6">
                                    <div className="card border-0 shadow">
                                        <div className="card-header border-0">
                                            <h3 className='fw-bold'>Codigo formulario</h3>
                                        </div>
                                        <div className="card-body">
                                            <form onSubmit={handleSubmitCode(handdleValidateCode)} >
                                                <div className="input-group mb-3">
                                                    <button className="btn btn-primary btn-outline-secondary" type="button" id="button-addon2" name="Generar Código">
                                                        <i className="bi bi-upc text-white"></i>
                                                    </button>
                                                    <input {...registerCode("codigo", { required: "Desbes escribir el codigo" })} placeholder="XXXXXXXX" type="text" className={`form-control ${errorsCode.codigo ? 'is-invalid' : ''}`} id="codigo" />
                                                    <div className="invalid-feedback">
                                                        {errorsCode.codigo?.message}
                                                    </div>
                                                </div>
                                                <div className="text-center mt-4">
                                                    {
                                                        loading ? (
                                                            <button className="btn btn-primary" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                &nbsp; validando...
                                                            </button>
                                                        ) : (
                                                            <button className="btn btn-primary text-center" type="submit">
                                                                Validar
                                                            </button>
                                                        )
                                                    }

                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </main>
                        )
                    }

                </div >
            </section >
        </>
    );
}

export default Form;