import { toast } from "react-toastify";
import { config } from "../../../core/config/config";

const base_url = config.baseUrl;
const endpoint = "/form";
const token = localStorage.getItem("token");

const headers = {
    "Authorization": `Bearer ${token}`,
    "Content-Type": "application/json"
}

async function ObtenerFormularios(data) {
    try {
        const queryParams = new URLSearchParams(data);
        const url = `${base_url + endpoint}?${queryParams}`;

        const response = await fetch(url, {
            method: "GET",
            headers: headers
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al obtener los datos');
        }

        return { success: true, data: result };

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}

async function ObtenerFormulario(id) {
    try {
        const url = `${base_url + endpoint}/${id}`;

        const response = await fetch(url, {
            method: "GET",
            headers: headers
        });

        const result = await response.json();

        if (!response.ok) {
            throw new Error(result.message || 'Error al obtener los datos');
        }

        return { success: true, data: result };

    } catch (error) {
        toast.error(error.message || 'Ha ocurrido un error', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
        });

        return { success: false, message: error.message };
    }
}


export default {
    ObtenerFormularios,
    ObtenerFormulario
};