import React, { useState } from 'react'
import { useUserAuth } from "../../core/providers/UserAuthContext";
import { Navigate } from "react-router-dom";

import logo from '../../assets/img/logo.png'

import '../../assets/style/sing-in.css';
import { toast, ToastContainer } from 'react-toastify';

function Login() {

    const [password, setPassword] = useState();
    const [username, setUsername] = useState();

    const { user, logIn } = useUserAuth();

    if (user) {
        return <Navigate to="/dashboard/home" />;
    }

    const handdleLogin = async (e) => {
        e.preventDefault();
        try {
            const result = await logIn(username, password);
            if (!result.success) {
                toast.error(result.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            // navigate("/dashboard")
        } catch (err) {
            console.log(err.message);
        }
    }

    return (
        <>
            <ToastContainer />
            <div className="d-flex align-items-center py-4 bg-body-tertiary vh-100">
                <main className="form-signin w-100 m-auto">
                    <form>
                        <div className='text-center'>
                            <img className="mb-4" src={logo} alt="" width="72" height="57" />
                            <h1 className="h3 mb-3 fw-normal">Inicie sesión</h1>
                        </div>
                        <div className="form-floating">
                            <input onChange={(e) => { setUsername(e.target.value) }} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" />
                            <label htmlFor="floatingInput">Correo</label>
                        </div>
                        <div className="form-floating">
                            <input onChange={(e) => { setPassword(e.target.value) }} type="password" className="form-control" id="floatingPassword" placeholder="*********" />
                            <label htmlFor="floatingPassword">*********</label>
                        </div>

                        {/* <div className="form-check text-start my-3">
                        <input className="form-check-input" type="checkbox" value="remember-me" id="flexCheckDefault" />
                        <label className="form-check-label" htmlFor="flexCheckDefault">
                            Remember me
                        </label>
                    </div> */}
                        <button onClick={handdleLogin} className="btn btn-primary w-100 py-2" type="submit">Ingresar</button>
                        <p className="mt-5 mb-3 text-body-secondary">&copy; Vangar SAS 2024</p>
                    </form>
                </main>
            </div>
        </>
    )
}

export default Login;