import React from "react";

import logo from "../../../assets/img/logo.png"

const Home = () => {
    return (
        <>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h1 className="h2">Bienvenido</h1>
            </div>
            <div className="row">
                <div className="col-md-2"></div>
                <div className="col-md-8 text-center">
                    <img src={logo} width={250} height={200} />
                    <h1 className="fw-bold text-gold" style={{"fontSize": "4.5rem"}}>Asilo a tu alcance</h1>
                    <p className="text-gold" style={{"fontSize": "2rem"}}>ASESORÍA MIGRATORIA</p>
                </div>
                <div className="col-md-2"></div>
            </div>
        </>
    )
}

export default Home;